import * as React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import getApiClient from 'utils/apiClient'
import { TYPE_IDS } from 'utils/constants'
import { useTranslations } from 'containers/Translations/TranslationsContext'

const UserHandlersContext = React.createContext({})
const UserContext = React.createContext({})

if (process.env.NODE_ENV !== 'production') {
  UserHandlersContext.displayName = 'UserHandlersContext'
  UserContext.displayName = 'UserContext'
}

export function useUserHandlers() {
  return React.useContext(UserHandlersContext)
}

export function useUser() {
  return React.useContext(UserContext)
}

const apiClient = getApiClient()

export function UserProvider(props) {
  const { children } = props

  const [initiated, setInitiated] = React.useState(false)
  const [user, setUser] = React.useState({})
  const [error, setError] = React.useState({})
  const [subscriptionStatuses, setSubscriptionStatuses] = React.useState([])
  const [subscriptions, setSubscriptions] = React.useState([])
  const [paymentMethods, setPaymentMethods] = React.useState([])
  const [availablePaymentMethods, setAvailablePaymentMethods] = React.useState([])
  const [orderHistory, setOrderHistory] = React.useState([])
  const [consents, setConsents] = React.useState([])
  const [userVouchers, setUserVouchers] = React.useState({})
  const [commissionsOrderHistory, setCommissionsOrderHistory] = React.useState([])
  const [affiliateCode, setAffiliateCode] = React.useState('')
  const [availableBanks, setAvailableBanks] = React.useState([])
  const [payoutStatus, setPayoutStatus] = React.useState({})
  const [payoutHistory, setPayoutHistory] = React.useState([])
  const [requestedPayout, setRequestedPayout] = React.useState({})
  const [token, setToken] = React.useState()
  const [widgetToken, setWidgetToken] = React.useState()
  const [biomaKits, setBiomaKits] = React.useState()
  const [myGutItems, setMyGutItems] = React.useState([])
  const [addressTypes, setAddressTypes] = React.useState([])

  const t = useTranslations()

  const personalUrl = `${t('Web.UrlBase')}${affiliateCode}`

  const isAuthenticated = !!user?.email
  const isAmbassadorUser = user?.userTypeId >= TYPE_IDS.userTypes.ambassador

  const pendingAmbassadorApplication = consents?.find(
    (c) => c.customInfoTypeId === TYPE_IDS.consents.pendingAmbassadorApplication,
  )?.value?.value

  const phoneNumber = user.contacts?.find(
    (c) => c.contactTypeId === TYPE_IDS.contacts.phoneNumber,
  )?.value

  const socialMediaAccounts = {
    facebook: user.contacts?.find((c) => c.contactTypeId === TYPE_IDS.contacts.facebook)?.value,
    twitter: user.contacts?.find((c) => c.contactTypeId === TYPE_IDS.contacts.twitter)?.value,
    instagram: user.contacts?.find((c) => c.contactTypeId === TYPE_IDS.contacts.instagram)?.value,
  }

  const marketingConsents = {
    newsletter: consents?.find((c) => c.customInfoTypeId === TYPE_IDS.consents.newsletter)?.value
      ?.value,
  }

  const phonePattern = '^.{7,14}$'

  React.useEffect(() => {
    const init = async () => {
      try {
        if (!initiated) {
          const checkUser = await apiClient.checkUser()

          if (checkUser.loggedIn) {
            const userData = await apiClient.getUser()

            if (userData?.email) {
              setUser(userData)
            }

            if (userData?.affiliateCode) {
              setAffiliateCode(userData.affiliateCode)
            }
          }
        }
      } catch (err) {
        setError(err)
      }

      setInitiated(true)
    }

    setToken(Cookies.get('SESSION_TOKEN'))
    setWidgetToken(Cookies.get('WIDGET_TOKEN'))

    init()
  }, [isAuthenticated, initiated, user])

  const onGetUser = React.useCallback(async () => {
    const data = await apiClient.getUser()

    setUser(data)
  }, [])

  const onGetAddressTypes = React.useCallback(async () => {
    const data = await apiClient.getAddressTypes()

    setAddressTypes(data)
  }, [])

  const onUserUpdate = React.useCallback(async (values) => {
    const data = await apiClient.updateUser({ user: values })

    setUser(data)
  }, [])

  const onCheckEmail = React.useCallback(async (values) => {
    const data = await apiClient.checkEmail(values)

    return data
  }, [])

  const onUserAuthenticate = React.useCallback(async (values) => {
    const data = await apiClient.signIn(values)

    if (data?.user) {
      setUser(data.user)

      if (window.Ebbot) {
        window.Ebbot.setUserAttribute({
          token: data.token,
          logged_in: true,
        })

        if (typeof window.Ebbot.isConversationActive === 'function') {
          const isInit = window?.Ebbot?.isConversationActive() || false

          if (isInit) {
            window.Ebbot.triggerScenario({
              scenario: '914f87a0-7c8f-11ee-9e94-bf3198ffcba2_2b9aa84b-ddb9-4c46-bd2d-bb070d18be09',
            })
          }
        }
      }
    }
  }, [])

  const onUserRegister = React.useCallback(async (values) => {
    await apiClient.register(values)
  }, [])

  const onAmbassadorApplication = React.useCallback(async (values) => {
    const data = await apiClient.ambassadorApplication(values)

    return data
  }, [])

  const onUserRecoverPassword = React.useCallback(async (values) => {
    const data = await apiClient.resetPasswordRequest(values)

    return data
  }, [])

  const onUserSignOut = React.useCallback(async (all) => {
    try {
      await apiClient.signOut(all)

      // TODO: Is this the correct sign out/deactivate method?
      setUser({})
      setInitiated(false)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onUserDeactivate = React.useCallback(async () => {
    const data = await apiClient.deactivate()

    return data
  }, [])

  const onGetConsumablesVouchers = React.useCallback(async () => {
    try {
      const data = await apiClient.getConsumablesVouchers()

      setUserVouchers(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onEbbotInit = React.useCallback(
    async (loggedIn, tokenP) => {
      if (typeof window.Ebbot?.setUserAttribute === 'function') {
        await window.Ebbot.setUserAttribute({
          token: tokenP || token,
          logged_in: loggedIn || isAuthenticated || false,
        })
      }
    },
    [isAuthenticated, token],
  )

  const onAddBonusRequest = React.useCallback(async (periodId) => {
    await apiClient.addBonusRequest(periodId)
  }, [])

  const onGetPayoutStatus = React.useCallback(async () => {
    try {
      const data = await apiClient.getPayoutStatus()

      setPayoutStatus(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onGetPSCOrderCalculation = React.useCallback(async (periodId) => {
    try {
      const data = await apiClient.getPSCOrderCalculation(periodId)

      setCommissionsOrderHistory(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onGetAffiliateCode = React.useCallback(async (params) => {
    try {
      const data = await apiClient.generateAffiliateCode(params)

      setAffiliateCode(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onSaveAffiliateCode = React.useCallback(async (params) => {
    try {
      const data = await apiClient.saveAffiliateCode(params)

      setAffiliateCode(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onGetListOfAvailablConsents = React.useCallback(async () => {
    try {
      const consentsData = await apiClient.getListOfAvailablConsents()
      setConsents(consentsData)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onCreateConsentValue = React.useCallback(async (params) => {
    try {
      await apiClient.createConsentValue(params)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onUpdateConsentValue = React.useCallback(async (params) => {
    await apiClient.updateConsentValue(params)
  }, [])

  const onGetSubscriptionStatuses = React.useCallback(async () => {
    try {
      const subscriptionStatusesData = await apiClient.getSubscriptionStatuses()
      setSubscriptionStatuses(subscriptionStatusesData)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onGetSubscriptions = React.useCallback(async () => {
    try {
      const subscriptionsData = await apiClient.getSubscriptions()
      setSubscriptions(subscriptionsData)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onCreateSubscription = React.useCallback(async (values) => {
    await apiClient.createSubscription({
      product_subscription: {
        product_variant_id: values.productVariantId,
        product_variant_price_id: values.productVariantPriceId,
        quantity: values.quantity || 1,
        frequency: values.frequency,
        start_date: values.startDate || new Date().toISOString(),
        end_date: values.endDate,
        shipment_address_id: values.shipmentAddressId,
        comment: values.comment,
        next_shipment_date: values.nextShipmentDate,
        first_order_id: values.firstOrderId,
        user_id: values.userId,
        currency_id: values.currencyId,
        product_subscription_status_id: values.productSubscriptionStatusId,
        shipment_method_id: values.shipmentMethodId,
        warehouse_id: values.warehouseId || 59,
        user_bank_id: values.userBankId,
        use_discounts: values.useDiscounts,
        contact_id: values.contactId,
        affiliate_code: values.affiliateCode,
        installments: values.installments || 1,
        multi_steps: values.multi_steps || 1,
      },
    })
  }, [])

  const onUpdateSubscription = React.useCallback(async (values) => {
    await apiClient.updateSubscription(values.subscriptionId, {
      product_subscription: {
        product_subscription_status_id: values.subscriptionStatusId,
        product_variant_price_id: values.productVariantPriceId,
        product_variant_id: values.productVariantId,
        shipment_address_id: values.shipmentAddressId,
        user_bank_id: values.userBankId,
        next_shipment_date: values.nextShipmentDate,
        use_discounts: values.useDiscount,
        quantity: values.quantity,
        frequency: values.frequency,
      },
    })
  }, [])

  const onGetAvailableBanks = React.useCallback(async () => {
    try {
      const data = await apiClient.getAvailableBanks()

      setAvailableBanks(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onGetPaymentMethods = React.useCallback(async () => {
    try {
      const data = await apiClient.getPaymentMethods()

      setPaymentMethods(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onCreatePaymentMethod = React.useCallback(
    async (params) => {
      await apiClient.createPaymentMethod(params)

      onGetPaymentMethods()
    },
    [onGetPaymentMethods],
  )

  const onGetAvailablePaymentMethods = React.useCallback(async () => {
    try {
      const data = await apiClient.availablePaymentMethods()

      setAvailablePaymentMethods(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onUpdatePaymentMethod = React.useCallback(
    async (id, params) => {
      await apiClient.updatePaymentMethod(id, params)

      onGetPaymentMethods()
    },
    [onGetPaymentMethods],
  )

  const onRemovePaymentMethod = React.useCallback(async (paymentMethodId) => {
    await apiClient.deletePaymentMethod(paymentMethodId)
  }, [])

  const onGetOrder = React.useCallback(async () => {
    try {
      const orderData = await apiClient.getOrderHistory()
      setOrderHistory(orderData)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onResetUserPassword = React.useCallback(
    async (passwords, passwordToken = token) => {
      await apiClient.resetUserPassword(passwords, passwordToken)
    },
    [token],
  )

  const onResetSessionPassword = React.useCallback(
    async (params, passwordToken = token) => {
      const data = await apiClient.resetSessionPassword(params, passwordToken)
      return data
    },
    [token],
  )

  const onAddAddress = React.useCallback(async (address) => {
    await apiClient.addAddress(address)
  }, [])

  const onChangeAddress = React.useCallback(async (addressId, address) => {
    await apiClient.changeAddress(addressId, address)
  }, [])

  const onAddContact = React.useCallback(async (contact) => {
    await apiClient.addContact(contact)
  }, [])

  const onChangeContact = React.useCallback(async (id, contact) => {
    await apiClient.changeContact(id, contact)
  }, [])

  const onRemoveContact = React.useCallback(async (id) => {
    await apiClient.removeContact(id)
  }, [])

  const onGetPayoutHistory = React.useCallback(async () => {
    try {
      const data = await apiClient.getPayoutHistory()
      setPayoutHistory(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onGetMyGutItems = React.useCallback(async () => {
    try {
      const data = await apiClient.getMyGutItems()

      setMyGutItems(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onGetBiomaKits = React.useCallback(async () => {
    try {
      const data = await apiClient.getMyBiomaKits()

      setBiomaKits(data)
    } catch (err) {
      setError(err)
    }
  }, [])

  const onRequestPayout = React.useCallback(async (params) => {
    const data = await apiClient.requestPayout(params)

    setRequestedPayout(data)
  }, [])

  const onResetRequestedPayout = React.useCallback(() => {
    setRequestedPayout({})
  }, [])

  const onGetSettings = React.useCallback(async () => {
    onGetAffiliateCode()
    onGetListOfAvailablConsents()
  }, [onGetAffiliateCode, onGetListOfAvailablConsents])

  // Memoize handlers context separately so that one can subscribe
  // to them without re-rendering on state updates.
  const handlersContextValue = React.useMemo(
    () => ({
      onGetAddressTypes,
      onUserDeactivate,
      onUserAuthenticate,
      onUserRecoverPassword,
      onGetUser,
      onUserUpdate,
      onCheckEmail,
      onUserRegister,
      onAmbassadorApplication,
      onUserSignOut,
      onGetConsumablesVouchers,
      onAddBonusRequest,
      onGetPayoutStatus,
      onGetPSCOrderCalculation,
      onGetAffiliateCode,
      onSaveAffiliateCode,
      onGetListOfAvailablConsents,
      onCreateConsentValue,
      onUpdateConsentValue,
      onCreateSubscription,
      onUpdateSubscription,
      onGetSubscriptionStatuses,
      onGetSubscriptions,
      onGetAvailableBanks,
      onGetPaymentMethods,
      onCreatePaymentMethod,
      onGetAvailablePaymentMethods,
      onUpdatePaymentMethod,
      onRemovePaymentMethod,
      onGetOrder,
      onResetUserPassword,
      onResetSessionPassword,
      onAddAddress,
      onChangeAddress,
      onAddContact,
      onChangeContact,
      onRemoveContact,
      onGetPayoutHistory,
      onRequestPayout,
      onResetRequestedPayout,
      onGetSettings,
      onGetBiomaKits,
      onGetMyGutItems,
      onEbbotInit,
    }),
    [
      onGetAddressTypes,
      onUserDeactivate,
      onUserAuthenticate,
      onUserRecoverPassword,
      onGetUser,
      onUserUpdate,
      onCheckEmail,
      onUserRegister,
      onAmbassadorApplication,
      onUserSignOut,
      onGetConsumablesVouchers,
      onAddBonusRequest,
      onGetPayoutStatus,
      onGetPSCOrderCalculation,
      onGetAffiliateCode,
      onSaveAffiliateCode,
      onGetListOfAvailablConsents,
      onCreateConsentValue,
      onUpdateConsentValue,
      onCreateSubscription,
      onUpdateSubscription,
      onGetSubscriptionStatuses,
      onGetSubscriptions,
      onGetAvailableBanks,
      onGetPaymentMethods,
      onCreatePaymentMethod,
      onGetAvailablePaymentMethods,
      onUpdatePaymentMethod,
      onRemovePaymentMethod,
      onGetOrder,
      onResetUserPassword,
      onResetSessionPassword,
      onAddAddress,
      onChangeAddress,
      onAddContact,
      onChangeContact,
      onRemoveContact,
      onGetPayoutHistory,
      onRequestPayout,
      onResetRequestedPayout,
      onGetSettings,
      onGetBiomaKits,
      onGetMyGutItems,
      onEbbotInit,
    ],
  )

  const contextValue = {
    error,
    user,
    subscriptionStatuses,
    subscriptions,
    paymentMethods,
    orderHistory,
    socialMediaAccounts,
    marketingConsents,
    phoneNumber,
    consents,
    widgetToken,
    affiliateCode,
    personalUrl,
    token,
    userVouchers,
    commissionsOrderHistory,
    payoutStatus,
    payoutHistory,
    addressTypes,
    // Computed props
    isAuthenticated,
    isAmbassadorUser,
    pendingAmbassadorApplication,
    availableBanks,
    availablePaymentMethods,
    requestedPayout,
    initiated,
    phonePattern,
    biomaKits,
    myGutItems,
    // Merge in handlers for easy access
    ...handlersContextValue,
  }

  return (
    <UserHandlersContext.Provider value={handlersContextValue}>
      <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
    </UserHandlersContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserContext
