/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import * as React from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { useRouter } from 'next/router'
import { debounce } from '@material-ui/core/utils'
import Cookies from 'js-cookie'
import getConfig from 'next/config'
import camelcaseKeys from 'camelcase-keys'
import { useGlobal, onNewsletterSignUp } from 'api'
import getApiClient from 'utils/apiClient'
import isEqual from 'utils/isEqual'
import { TYPE_IDS } from 'utils/constants'
import { formatPaymentMethods } from 'utils/formatters'
import { gtm, gtmFormatProduct } from 'containers/Gtm/GtmTracker'
import { useTranslations } from 'containers/Translations/TranslationsContext'

const CheckoutHandlersContext = React.createContext({})
const CheckoutContext = React.createContext({})

if (process.env.NODE_ENV !== 'production') {
  CheckoutHandlersContext.displayName = 'CheckoutHandlersContext'
  CheckoutContext.displayName = 'CheckoutContext'
}

export function useCheckoutHandlers() {
  return React.useContext(CheckoutHandlersContext)
}

export function useCheckout() {
  return React.useContext(CheckoutContext)
}

const apiClient = getApiClient()

export function CheckoutProvider(props) {
  const { children } = props
  const { onFormatCurrency, currencyData, language, deliveryCountries } = useGlobal()
  const t = useTranslations()

  const { publicRuntimeConfig } = getConfig()

  const [items, setItems] = React.useState([])
  const [order, setOrder] = React.useState({})
  const [formData, setFormData] = React.useState({})
  const [shipmentAddress, setShipmentAddress] = React.useState({})
  const [billingAddress, setBillingAddress] = React.useState({})
  const [receipt, setReceipt] = React.useState({})
  const [Klarna, setKlarna] = React.useState()
  const [Stripe, setStripe] = React.useState()
  const [selectedVouchers, setSelectedVouchers] = React.useState([])
  const [shipmentMethod, setShipmentMethod] = React.useState('')
  const [paymentMethod, setPaymentMethod] = React.useState('')
  const [klarnaPaymentMethodCategory, setKlarnaPaymentMethodCategory] = React.useState('pay_later')
  const [bonusAmount, setBonusAmount] = React.useState(0)
  const [error, setError] = React.useState()
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [stripeCard, setStripeCard] = React.useState({})
  const [useDiscount, setUseDiscount] = React.useState(false)
  const [loading, setLoading] = React.useState()
  const [applePayPaymentRequest, setApplePaymentRequst] = React.useState({})
  const [googlePayPaymentRequest, setGooglePayPaymentRequest] = React.useState({})
  const [userSavedCards, setUserSavedCards] = React.useState()
  const [basketSample, setBasketSample] = React.useState([])
  const [deliveryCountryId, setDeliveryCountryId] = React.useState(deliveryCountries[0].countryId)
  const [swishModalOpen, setSwishModalOpen] = React.useState(false)
  const [swishData, setSwishData] = React.useState({})
  const [swishSocket, setSwishSocket] = React.useState(null)

  const appliedVouchers = order.vouchersApplied?.filter((voucher) => voucher.applied)
  const shipmentFee = order.orderItems?.find((item) => item.lineType === 'F')?.orderPrice || 0

  const klarnaContainerRef = React.useRef(null)
  const stripeContainerRef = React.useRef(null)
  const basketSampleItems = React.useRef([])

  const ncpDebtPaymentMethod = order?.paymentMethods?.find((p) => p.type === 'NCP-DEBT')

  const klarnaPaymentMethod = order?.paymentMethods?.find((p) => p.type === 'KLARNA-PAYMENT')
  const klarnaPaymentMethods = klarnaPaymentMethod?.paymentMethodCategories
  const klarnaToken = klarnaPaymentMethod?.clientToken

  const stripePaymentMethod = order?.paymentMethods?.find((p) => p.type === 'STRIPE')
  const stripeKey = stripePaymentMethod?.publishableKey

  const applePayPaymentMethod = order?.paymentMethods?.find((p) => p.type === 'STRIPE-APPLEPAY')
  const googlePayPaymentMethod = order?.paymentMethods?.find((p) => p.type === 'STRIPE-GOOGLEPAY')

  const swishPaymentMethod = order?.paymentMethods?.find((p) => p.type === 'SWISH')

  const bonusPaymentMethod = order?.paymentMethods?.find((p) => p.type === 'PREPAID')
  const bonusPaymentMethodId = bonusPaymentMethod?.id
  const bonusPayment = order.paymentsApplied?.find(
    (p) => p.paymentMethodId === bonusPaymentMethodId,
  )

  const walletAmount = bonusPaymentMethod?.availableAmount

  const paymentMethods = formatPaymentMethods(order?.paymentMethods)

  const isEmpty = (obj) => Object.keys(obj).length === 0

  const router = useRouter()

  // Fetch basket on mount
  React.useEffect(() => {
    const init = async () => {
      try {
        const basketData = await apiClient.getBasket()

        const directPaymentPage = router.pathname

        if (basketData.basketConflict && directPaymentPage !== '/direct-payment-order') {
          // Auto-resolve
          await apiClient.resolveBasket()
        }

        if (basketData.basketItems) {
          setItems(basketData.basketItems)
        }
      } catch (err) {
        console.error(err)
      }
    }

    init()
  }, [router.pathname])

  React.useEffect(() => {
    const affiliateCode = Cookies.get('AFFILIATE_CODE')
    const referralCardCode = Cookies.get('REFERRAL_CARD')

    if (affiliateCode && !selectedVouchers.includes(affiliateCode)) {
      setSelectedVouchers((prev) => [...prev, affiliateCode?.toUpperCase()])
    }

    if (referralCardCode && !selectedVouchers.includes(referralCardCode)) {
      setSelectedVouchers((prev) => [...prev, referralCardCode?.toUpperCase()])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Evaluate order when basket is modified
  React.useEffect(() => {
    const vouchers = [...selectedVouchers]

    const fetchData = async () => {
      try {
        setLoading(true)
        const orderData = await apiClient.orderEvaluate({
          basket_items: items.map((item) => ({
            product_variant_id: item.productVariantId,
            qty: item.qtyRequested,
            product_variant_price_id: item.productVariantPriceId,
          })),
          productOffer: basketSample,
          shipmentMethodId: formData.shipmentMethod,
          shipmentMethodPickupPointId: formData.shipmentMethodPickupPointId,
          shipmentAddress: formData.shipping,
          billingAddress: formData.billing,
          codes: vouchers,
          use_discounts: useDiscount,
          email: formData.email,
          payments: bonusPaymentMethodId
            ? [{ paymentMethodId: bonusPaymentMethodId, amount: bonusAmount }]
            : [],
          contact: formData.phoneNumber
            ? {
                value: formData.phoneNumber,
              }
            : undefined,
        })

        // if (
        //   vouchers.length > 0 &&
        //   orderData.vouchersApplied?.[orderData.vouchersApplied.length - 1]?.code?.toUpperCase() !==
        //     vouchers[vouchers.length - 1]?.toUpperCase()
        // ) {
        //   setError({ data: { errors: [{ voucherError: t('Web.Checkout.Invalid.Code') }] } })
        //   const voucherRemoved = vouchers.length > 1 ? vouchers.slice(0, -1) : []
        //   setSelectedVouchers(voucherRemoved)
        // }

        setOrder(orderData)
        setLoading(false)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    }

    if (items.length > 0) {
      fetchData()
    } else {
      setOrder({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    items,
    selectedVouchers,
    formData,
    t,
    useDiscount,
    bonusPaymentMethodId,
    bonusAmount,
    basketSample,
    deliveryCountryId,
    formData?.shipmentMethod,
    setError,
  ])

  const removeReceipt = React.useCallback(() => {
    setReceipt({})
  }, [])

  const onSelectedVouchers = React.useCallback((value) => {
    setSelectedVouchers(value)
  }, [])

  const onSetUserSavedCards = React.useCallback((cards) => {
    setUserSavedCards(cards)
  }, [])

  const onSetDeliveryCountryId = React.useCallback(
    (id) => {
      setDeliveryCountryId(id)
      if (!isEmpty(formData)) {
        formData.shipping.countryStateId = ''
      }
    },
    [formData],
  )

  const basketItemExists = React.useCallback(
    (variantId, priceId, lineType) => {
      // Check for existing item in cart to update quantity if needed
      const exists = items.find(
        (i) =>
          i.productVariantId === Number(variantId) &&
          i.productVariantPriceId === Number(priceId) &&
          i.lineType === lineType,
      )
      return exists
    },
    [items],
  )

  const onSetSwishModalOpen = React.useCallback((value) => {
    setSwishModalOpen(value)
  }, [])

  const resetAfterSave = React.useCallback(() => {
    setItems([])
    setOrder({})
    setKlarna()
    setStripe()
    setSelectedVouchers([])
    setShipmentMethod('')
    setPaymentMethod('')
    setKlarnaPaymentMethodCategory('pay_later')
    setBonusAmount(0)
    setError()
    setPhoneNumber('')
    setStripeCard({})
  }, [])

  const onItemAdd = React.useCallback(
    async (variantId, priceId, lineType, rowId) => {
      try {
        const exists = basketItemExists(variantId, priceId, lineType, rowId)

        const basketData = await apiClient.updateBasket({
          purge_basket: false,
          basket_item: [
            {
              product_variant_id: variantId,
              product_variant_price_id: priceId,
              qty: exists?.qtyRequested + 1 || 1,
              row_id: rowId || null,
            },
          ],
        })

        setItems(basketData.basketItems)
        const newProduct = basketData.basketItems.find(
          (i) =>
            i.productVariantId === Number(variantId) && i.productVariantPriceId === Number(priceId),
        )
        gtm({
          event: 'addToCart',
          ecommerce: {
            currencyCode: currencyData.isoCodeAlpha,
            add: {
              products: [gtmFormatProduct(newProduct)],
            },
          },
        })

        return true
      } catch (err) {
        console.error(err)

        return false
      }
    },
    [basketItemExists, currencyData.isoCodeAlpha],
  )

  const onItemAmountChange = React.useCallback(
    async (variantId, priceId, amount, lineType, id) => {
      try {
        const oldProduct = order?.orderItems.find(
          (i) =>
            i.productVariantId === Number(variantId) &&
            i.productVariantPriceId === Number(priceId) &&
            i.lineType === lineType,
        )

        const basketData = await apiClient.updateBasket({
          purge_basket: false,
          basket_item: [
            {
              product_variant_id: variantId,
              product_variant_price_id: priceId,
              qty: id !== null && amount,
            },
          ],
        })

        const newProduct = basketData.basketItems.find(
          (i) =>
            i.productVariantId === Number(variantId) &&
            i.productVariantPriceId === Number(priceId) &&
            i.lineType === lineType,
        )

        setItems(basketData.basketItems)

        const delta = (newProduct?.qtyRequested || 0) - (oldProduct?.qtyRequested || 0)

        gtm({
          event: delta > 0 ? 'addToCart' : 'removeFromCart',
          ecommerce: {
            currencyCode: currencyData.isoCodeAlpha,
            [delta > 0 ? 'add' : 'remove']: {
              products: [gtmFormatProduct({ ...newProduct, qtyRequested: Math.abs(delta) })],
            },
          },
        })
      } catch (err) {
        console.error(err)
      }
    },
    [order?.orderItems, currencyData.isoCodeAlpha],
  )

  const onItemRemove = React.useCallback(
    // eslint-disable-next-line consistent-return
    async (basketItem) => {
      try {
        const oldProduct = order?.orderItems?.find((i) => i.basketItemId === Number(basketItem))
        const basketData = await apiClient.removeBasketItem(basketItem)

        setItems(basketData.basketItems)

        if (oldProduct) {
          gtm({
            event: 'removeFromCart',
            ecommerce: {
              currencyCode: currencyData.isoCodeAlpha,
              remove: {
                products: [gtmFormatProduct(oldProduct)],
              },
            },
          })
        }

        return basketData
      } catch (err) {
        console.error(err)
      }
    },
    [order?.orderItems, currencyData.isoCodeAlpha],
  )

  const onHandleSamples = React.useCallback(
    async (offer, rowId) => {
      const exists = basketSampleItems.current.findIndex((item) => item.rowId === rowId)

      if (exists === -1) {
        basketSampleItems.current.push({ rowId, offer })
      } else {
        basketSampleItems.current[exists].offer = offer
      }

      const productOffer =
        basketSampleItems.length !== 0
          ? basketSampleItems?.current?.map((item) => {
              let offerMap = {}
              offerMap = {
                product_variant_id: item?.offer?.productVariantId,
                qty: 1,
                product_variant_price_id: item?.offer?.productVariantPriceId,
                sa_action_id: 44,
                row_id: item?.rowId,
              }
              return offerMap
            })
          : []

      setBasketSample(productOffer)
    },
    [basketSampleItems],
  )

  const onAddCode = React.useCallback((value) => {
    setSelectedVouchers((prev) => {
      if (prev.find((p) => p.toLowerCase() === value.toLowerCase())) {
        return [...prev]
      }

      return [...prev, value]
    })
  }, [])

  const onUseDiscounts = React.useCallback((value) => {
    setUseDiscount(value)
  }, [])

  const onRemoveCode = React.useCallback((value) => {
    if (Cookies.get('AFFILIATE_CODE')?.toUpperCase() === value) {
      Cookies.remove('AFFILIATE_CODE')
    }

    setSelectedVouchers((prev) => {
      const index = prev.indexOf(value)
      if (index !== -1) {
        prev.splice(index, 1)
      }
      return [...prev]
    })
  }, [])

  const onBonusAmountChange = React.useCallback((value) => {
    setBonusAmount(value)
  }, [])

  const onSetStripeCard = React.useCallback((value) => {
    setStripeCard(value)
  }, [])

  const onSetApplePaymentRequest = React.useCallback((value) => {
    setApplePaymentRequst(value)
  }, [])

  const onSetGooglePayPaymentRequest = React.useCallback((value) => {
    setGooglePayPaymentRequest(value)
  }, [])

  const executeOrder = React.useCallback(
    async (values, setSubmiting, paymentMethodProp) => {
      const parsedPaymentMethod = Math.max(
        Number(
          formData.paymentMethod.includes(':')
            ? formData.paymentMethod.indexOf(':') + 1
            : formData.paymentMethod,
        ),
        1,
      )

      const orderPaymentMethods = [paymentMethodProp]
      setBillingAddress(formData.billing)
      setShipmentAddress(formData.shipmentAddress)
      setPhoneNumber(formData.phoneNumber)

      if (bonusAmount) {
        orderPaymentMethods.unshift({
          paymentMethodId: bonusPaymentMethodId,
          amount: bonusAmount,
        })
      }

      const handleStripe3DSResponse = async (clientSecret) => {
        Stripe.confirmCardPayment(clientSecret).then((r) => {
          if (r.paymentIntent) {
            executeOrder(values, setSubmiting, {
              paymentMethodId: parsedPaymentMethod,
              paymentToken: r.paymentIntent.paymentMethod,
              save: values.savePaymentMethod,
              paymentIntentId: r.paymentIntent.id,
            })
          } else {
            setSubmiting(false)
            setError({ data: { errors: [r.error.message] } })
          }
        })
      }

      try {
        const data = {
          orderProcessingId: order.id,
          email: values.contact?.email,
          contact: {
            contactTypeId: TYPE_IDS.phoneNumber,
            value: values.contact?.phoneNumber,
          },
        }
        if (order.orderDebt > 0 || bonusAmount > 0) data.paymentMethods = orderPaymentMethods

        const paymentData = await apiClient.orderSave(data)

        if (values.newsletterSignUp) {
          try {
            await onNewsletterSignUp({ email: values?.contact?.email })
          } catch (err) {
            console.error(err)
          }
        }

        if (paymentData?.orderProcessingId) setReceipt(paymentData)
        resetAfterSave()
        window.scrollTo(0, 0)
      } catch (err) {
        const stripe3dSData = err?.data?.errors?.OrderSave?.find((a) => a.hasOwnProperty('stripe'))
        const swishErrData = err?.data?.errors?.OrderSave?.find((a) => a === 'payment_request_sent')

        if (swishErrData) return
        if (stripe3dSData) {
          await handleStripe3DSResponse(stripe3dSData.stripe?.client_secret)
        } else {
          setError(err)
          setSubmiting(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      Stripe,
      bonusAmount,
      bonusPaymentMethodId,
      formData?.billing,
      formData?.paymentMethod,
      formData?.phoneNumber,
      formData?.shipmentAddress,
      order?.id,
      order?.orderDebt,
      swishModalOpen,
    ],
  )

  const onOpenSwishSocket = React.useCallback(
    (setSubmiting) => {
      const token = Cookies.get('SESSION_TOKEN')
      const uuid = Cookies.get('UUID')

      const ws = new WebSocket(`${publicRuntimeConfig.PURPOSELY_SOCKET_URL}?token=${token}`)
      setSwishSocket(ws)

      let heartbeatInterval

      ws.onopen = () => {
        const connect = {
          topic: `User:Swish-${uuid}`,
          event: 'phx_join',
          payload: {},
          ref: `User:Swish-${uuid}`,
        }

        ws.send(JSON.stringify(connect))

        heartbeatInterval = setInterval(() => {
          const heartbeat = {
            topic: `User:Swish-${uuid}`,
            event: 'ping',
            payload: {},
            ref: `User:Swish-${uuid}`,
          }

          ws.send(JSON.stringify(heartbeat))
        }, 10000)
      }

      ws.onmessage = async (event) => {
        const data = JSON.parse(event.data)

        if (data?.payload?.callback_url || data?.payload?.binary_qr_code) {
          setSwishModalOpen(true)
        }

        if (data?.payload?.callback_url) {
          setSwishData((prev) => ({ ...prev, callbackUrl: data?.payload?.callback_url }))
        }

        if (data?.payload?.binary_qr_code) {
          const qrCode = data?.payload?.binary_qr_code
          const qrCodeBase64 = qrCode.toString('base64')

          const qrCodeImage = `data:image/png;base64,${qrCodeBase64}`
          setSwishData((prev) => ({ ...prev, qrCode: qrCodeImage }))
        }

        if (data?.payload?.status?.toUpperCase() === 'SUCCESS') {
          if (data?.payload?.order_data) {
            resetAfterSave()
            const receiptSwishData = camelcaseKeys(data?.payload?.order_data, { deep: true })
            setReceipt(receiptSwishData)

            document.body.style.overflowY = 'auto'
          }

          clearInterval(heartbeatInterval)
          ws.close()
          setSwishModalOpen(false)
          setSubmiting(false)
        }

        if (data?.payload?.status?.toUpperCase() === 'ERROR') {
          ws.close()
          setError({
            data: { errors: [data?.payload?.message || t('Web.Checkout.Error.Swish')] },
          })
          setSwishModalOpen(false)
          setSubmiting(false)
        }
      }

      ws.onclose = () => {
        clearInterval(heartbeatInterval)
        setSwishModalOpen(false)
        setSubmiting(false)
        console.error('Socket closed')
      }

      ws.onerror = (err) => {
        setSubmiting(false)
        setSwishModalOpen(false)
        console.error('Socket error', err)
      }

      return () => {
        ws.close()
      }
    },
    [publicRuntimeConfig.PURPOSELY_SOCKET_URL, resetAfterSave, t],
  )

  const onSetCheckoutError = React.useCallback((value) => {
    setError(value)
  }, [])

  const prevFormitStateRef = React.useRef({})

  const onFormitChange = debounce(
    ({ values }) => {
      const {
        shipping,
        shipping: { city, postcode, countryId, countryStateId },
        billing,
        contact,
      } = values

      const data = values

      if (
        !isEqual(prevFormitStateRef.current.shipping, shipping) &&
        city !== '' &&
        postcode !== '' &&
        countryId !== '' &&
        countryStateId !== ''
      ) {
        data.shipping = shipping
        data.shipping.countryStateId = Number(countryStateId)
      }

      if (
        !values.shipmentMethod ||
        prevFormitStateRef.current.shipmentMethods.length !== order.shipmentMethods.length
      ) {
        data.shipmentMethod = order?.shipmentMethods?.[0]?.id
        setShipmentMethod(data.shipmentMethod)
      }

      if (
        !shipping.recipientName ||
        prevFormitStateRef.current.shipping?.firstName !== shipping.firstName ||
        prevFormitStateRef.current.shipping?.lastName !== shipping.lastName
      ) {
        data.shipping.recipientName = `${shipping.firstName} ${shipping.lastName}`
      }

      if (
        !shipping.countryId ||
        !isEqual(prevFormitStateRef.current.shipping?.countryId, deliveryCountryId)
      ) {
        data.shipping.countryId = parseInt(deliveryCountryId, 10)
      }

      if (!isEqual(prevFormitStateRef.current.billing, billing)) {
        data.billing = billing
      }

      if (prevFormitStateRef.current.contact?.phoneNumber !== contact.phoneNumber) {
        data.phoneNumber = contact.phoneNumber
      }

      if (prevFormitStateRef.current.contact?.email !== contact.email) {
        data.email = contact.email
      }

      if (prevFormitStateRef.current.shipmentMethod !== values.shipmentMethod) {
        data.shipmentMethod = values.shipmentMethod
        setShipmentMethod(data.shipmentMethod)
      }

      if (prevFormitStateRef.current.paymentMethod !== values.paymentMethod) {
        let parsedPaymentMethod = values.paymentMethod
        let klarnaPaymentType
        if (parsedPaymentMethod.includes(':')) {
          klarnaPaymentType = parsedPaymentMethod.substring(parsedPaymentMethod.indexOf(':') + 1)

          parsedPaymentMethod = parsedPaymentMethod.substring(0, parsedPaymentMethod.indexOf(':'))

          klarnaContainerRef.current = document.getElementsByClassName(
            `klarna-container-${klarnaPaymentType}`,
          )[0]

          setPaymentMethod(parsedPaymentMethod)
          setKlarnaPaymentMethodCategory(klarnaPaymentType)
        } else {
          setPaymentMethod(values.paymentMethod)
        }

        data.paymentMethod = values.paymentMethod

        const method = order?.paymentMethods?.find((p) => p.id === Number(parsedPaymentMethod))

        if (method) {
          gtm({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: {
                  step: 4,
                  option: `Payment method: ${method.name}${
                    klarnaPaymentType ? ` ${klarnaPaymentType}` : ''
                  }`,
                },
                products: [items.map(gtmFormatProduct)],
              },
            },
          })
        }
      }

      prevFormitStateRef.current = values
      prevFormitStateRef.current.shipmentMethods = order?.shipmentMethods
      setFormData(data)
    },
    [items, order?.paymentMethods],
  )

  const onCheckoutSubmit = React.useCallback(
    async (values, setSubmiting) => {
      const parsedPaymentMethod = Math.max(
        Number(
          formData.paymentMethod.includes(':')
            ? formData.paymentMethod.indexOf(':') + 1
            : formData.paymentMethod,
        ),
        1,
      )

      const userSavedCardPaymentMethod = userSavedCards?.find(
        (card) => card?.id === parsedPaymentMethod,
      )

      const handleApplePayGooglePay = async (paymentRequest) => {
        await paymentRequest.show()

        await paymentRequest.on('paymentmethod', async (ev) => {
          try {
            await executeOrder(values, setSubmiting, {
              paymentToken: ev.paymentMethod.id,
              paymentMethodId: parsedPaymentMethod,
            })
            ev.complete('success')
          } catch (err) {
            ev.complete('fail')
          }
        })
        await paymentRequest.on('cancel', () => {
          setSubmiting(false)
        })
      }

      const handleKlarnaAuthorizeResponse = async (res) => {
        // eslint-disable-next-line no-console
        console.log('authorize callback', res, order)

        if (res.approved && res.authorization_token) {
          await executeOrder(values, setSubmiting, {
            paymentMethodId: parsedPaymentMethod,
            paymentAuthorizationToken: res.authorization_token,
            save: values.savePaymentMethod,
          })
        } else {
          setSubmiting(false)
          setError({ data: { errors: [t('Web.Checkout.KlarnaError')] } })
        }
      }

      switch (parsedPaymentMethod) {
        case klarnaPaymentMethod?.id: {
          Klarna.Payments.authorize(
            {
              payment_method_category: klarnaPaymentMethodCategory,
            },
            false,
            handleKlarnaAuthorizeResponse,
          )
          break
        }
        case stripePaymentMethod?.id: {
          const result = await Stripe?.createPaymentMethod(stripeCard)

          if (stripeCard.card) {
            await executeOrder(values, setSubmiting, {
              paymentMethodId: parsedPaymentMethod,
              paymentToken: result.paymentMethod.id,
              save: values.savePaymentMethod,
            })
          }
          break
        }
        case googlePayPaymentMethod?.id: {
          if (!googlePayPaymentRequest || isEmpty(googlePayPaymentRequest)) {
            setError({ data: { errors: [t('Web.Checkout.Payment.NotAvailable')] } })
            setSubmiting(false)
          } else {
            handleApplePayGooglePay(googlePayPaymentRequest)
          }
          break
        }
        case applePayPaymentMethod?.id: {
          if (!applePayPaymentRequest || isEmpty(applePayPaymentRequest)) {
            setError({ data: { errors: [t('Web.Checkout.Payment.NotAvailable')] } })
            setSubmiting(false)
          } else {
            handleApplePayGooglePay(applePayPaymentRequest)
          }
          break
        }
        case swishPaymentMethod?.id: {
          onOpenSwishSocket(setSubmiting)
          await executeOrder(values, setSubmiting, {
            paymentMethodId: parsedPaymentMethod,
          })

          break
        }
        case ncpDebtPaymentMethod?.id: {
          await executeOrder(values, setSubmiting, {
            paymentMethodId: parsedPaymentMethod,
          })
          break
        }
        case userSavedCardPaymentMethod?.id: {
          const userCardPaymentMethod = order?.paymentMethods?.find(
            (p) => p.name === userSavedCardPaymentMethod?.bankName,
          )

          await executeOrder(values, setSubmiting, {
            paymentMethodId: userCardPaymentMethod?.id,
            userBankId: userSavedCardPaymentMethod?.id,
          })
          break
        }

        default: {
          executeOrder(values, setSubmiting)
        }
      }
    },
    [
      formData?.paymentMethod,
      userSavedCards,
      executeOrder,
      order,
      t,
      klarnaPaymentMethod?.id,
      stripePaymentMethod?.id,
      googlePayPaymentMethod?.id,
      applePayPaymentMethod?.id,
      swishPaymentMethod?.id,
      ncpDebtPaymentMethod?.id,
      Klarna?.Payments,
      klarnaPaymentMethodCategory,
      Stripe,
      stripeCard,
      googlePayPaymentRequest,
      applePayPaymentRequest,
      onOpenSwishSocket,
    ],
  )

  // Initialize Klarna
  React.useEffect(() => {
    if (klarnaToken) {
      if (document.getElementById('klarna-jssdk')) {
        return
      }

      const js = document.createElement('script')

      js.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js'
      js.id = 'klarna-jssdk'

      window.klarnaAsyncCallback = () => {
        setKlarna(window.Klarna)
      }

      document.body.appendChild(js)
    }
  }, [klarnaToken])

  React.useEffect(() => {
    if (klarnaToken && Klarna && klarnaContainerRef.current !== null) {
      try {
        Klarna.Payments.init({
          client_token: klarnaToken,
        })

        Klarna.Payments.load(
          {
            container: `.klarna-container-${klarnaPaymentMethodCategory}`,
            payment_method_category: klarnaPaymentMethodCategory,
          },
          false,
        )
      } catch (e) {
        // Handle error
        console.error('Klarna error', e)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [klarnaToken, klarnaPaymentMethodCategory, Klarna, klarnaContainerRef.current])

  // Initialize Stripe
  React.useEffect(() => {
    const initStripe = async () => {
      if (stripeKey && !Stripe) {
        setStripe(
          await loadStripe(stripeKey, {
            locale: language.lang,
          }),
        )
      }
    }

    initStripe()
  }, [stripeKey, Stripe, language])

  // Memoize handlers context separately so that one can subscribe
  // to them without re-rendering on state updates.
  const checkoutHandlersContext = React.useMemo(
    () => ({
      onItemAdd,
      onItemAmountChange,
      onItemRemove,
      onAddCode,
      onUseDiscounts,
      onRemoveCode,
      onBonusAmountChange,
      onCheckoutSubmit,
      removeReceipt,
      onSelectedVouchers,
      onSetStripeCard,
      onSetCheckoutError,
      onSetApplePaymentRequest,
      onSetGooglePayPaymentRequest,
      onSetUserSavedCards,
      onSetDeliveryCountryId,
      onFormitChange,
      onSetSwishModalOpen,
      onHandleSamples,
    }),
    [
      onItemAdd,
      onItemAmountChange,
      onItemRemove,
      onUseDiscounts,
      onAddCode,
      onRemoveCode,
      onBonusAmountChange,
      onCheckoutSubmit,
      removeReceipt,
      onSelectedVouchers,
      onSetStripeCard,
      onSetCheckoutError,
      onSetApplePaymentRequest,
      onSetGooglePayPaymentRequest,
      onSetDeliveryCountryId,
      onSetUserSavedCards,
      onFormitChange,
      onSetSwishModalOpen,
      onHandleSamples,
    ],
  )

  const totals = React.useMemo(
    () => ({
      itemsTotalPrice: onFormatCurrency(order.orderSubtotal || 0),
      grandTotalPrice: onFormatCurrency(order.orderDebt || 0),
      totalQuantity: items?.reduce((acc, curr) => {
        acc += curr.qtyRequested

        return acc
      }, 0),
    }),
    [order, items, onFormatCurrency],
  )

  const checkoutContext = {
    items,
    order,
    shipmentMethod,
    paymentMethod,
    paymentMethods,
    receipt,
    shipmentAddress,
    billingAddress,
    formData,
    shipmentFee,
    selectedVouchers,
    appliedVouchers,
    bonusPaymentMethodId,
    bonusAmount,
    bonusPayment,
    walletAmount,
    totals,
    deliveryCountryId,
    klarnaPaymentMethodCategory,
    klarnaPaymentMethods,
    klarnaContainerRef,
    stripeContainerRef,
    Stripe,
    loading,
    error,
    phoneNumber,
    swishModalOpen,
    swishData,
    swishSocket,
    // Merge in handlers for easy access
    ...checkoutHandlersContext,
  }

  return (
    <CheckoutHandlersContext.Provider value={checkoutHandlersContext}>
      <CheckoutContext.Provider value={checkoutContext}>{children}</CheckoutContext.Provider>
    </CheckoutHandlersContext.Provider>
  )
}

CheckoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CheckoutContext
